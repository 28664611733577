import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { unmountComponentAtNode } from "react-dom";
import {base} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import { Modal } from 'reactstrap';
import Confetti from 'react-confetti';
import appBackgroundImage from '../../styles/images/background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import SlidingPuzzle from '../small_components/SlidingPuzzle'
import Loading from '../utils/Loading';
import { getDistance } from 'geolib';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import fileDownload from 'js-file-download';
const Axios = require('axios');

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: localStorage.getItem('userEmail') || false,
            active: false,
            width: 0,
            height: 0,
            alreadyUpdate: "",
            currentGameId: null,
            currentGameTime: false,
            currentResponseArray: [],
            startConfetti: false,
            rotationStarted: false,
            optedIntoEmail: {},
            tierMessaging: [],
            currentTiles: [],
            modal: false,
            winnerModal: false,
            correctImage: null,
            userHighScore: null,
            locationChecking: true,
            downloadingImage: false,
            downloadedFile: null,
            loading: true
        };
        this.logout = this.logout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    validateEmail(email) {
        let re;
        re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    onChangeHandler(event){
        navigator
            .share({
                files: event.target.files,
                text: 'Your Reward!',
                title: 'backgroundImage'
            })
    }

    download(url) {
        if(this.state.downloadedFile){
            fileDownload(this.state.downloadedFile, "reward.jpg")
            return
        }
        this.setState({
            downloadingImage: true
        })
        const variables = this.props.variables;
        const downloadImageRequest = {prizeImage: url, code: this.state.winningCode}
        const vm = this;
        // fetch(`http://localhost:5001/photoscrambledev/us-central1/setUpRewardForDownload`, {
        fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/setUpRewardForDownload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(downloadImageRequest),
        }).then(res => {
            if(res){
                Promise.resolve(res.json()).then(function (value) {
                    console.log(value)
                    if(!value || value.result === "no_prize_sent" || value.error){
                        confirmAlert({
                            variables: variables,
                            title: "Error",
                            message: "Something went wrong",
                            buttons: [
                                {
                                    label: 'OK'
                                }
                            ]
                        })
                        vm.setState({
                            downloadingImage:false
                        })
                    } else {
                        Axios.get(value.newImage, {
                            responseType: 'blob',
                        }).then(res => {
                            fileDownload(res.data, "reward.jpg")
                            vm.setState({
                                downloadingImage:false,
                                downloadedFile: res.data
                            })
                        });
                    }
                }).catch(e =>{
                    confirmAlert({
                        variables: variables,
                        title: "Error",
                        message: "Something went wrong",
                        buttons: [
                            {
                                label: 'OK'
                            }
                        ]
                    })
                    console.log(e)
                    vm.setState({
                        downloadingImage:false
                    })
                })
            } else {
                confirmAlert({
                    variables: variables,
                    title: "Error",
                    message: "Something went wrong",
                    buttons: [
                        {
                            label: 'OK'
                        }
                    ]
                })
                vm.setState({
                    downloadingImage:false
                })
            }
        })
    }

    componentDidMount(){
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.logUserActivity(key);
            this.removeReactAlert();
            this.setState({
              currentGameId:key,
              startConfetti: false
            })
          }
        }
      });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(!this.validateEmail(userEmail)){
          base64EncodedEmail = userEmail;
      }

      this.tilesRef = base.bindToState('currentGame/images', {
        context: this,
        state: 'currentTiles',
        asArray: true
      })

      this.correctImageRef = base.bindToState('currentGame/correctImage', {
        context: this,
        state: 'correctImage'
      })

      this.gameActiveRef = base.bindToState('currentGame/active', {
        context: this,
        state: 'active',
      })

      this.userHighScoreRef = base.bindToState(`userAnswers/${base64EncodedEmail}/numberOfMoves`, {
        context: this,
        state: 'userHighScore',
          then(){
              if(this.props.variables && this.props.variables.collectDistance && typeof this.state.userHighScore != "number"){
                  this.checkUsersLocation();
              } else {
                  this.setState({
                      locationChecking: false,
                      loading:false
                  })
              }
          }
      })
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0)

    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    changeMilesToMeters(milesToConvert){
        return milesToConvert*1609.344;
    }

    getLocation(){
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject("Geolocation is not supported by your browser. Please change browsers to play!");
            } else {
                const toCheckLatitude = this.props.variables.latitude || 51.525;
                const toCheckLongitude = this.props.variables.longitude || 7.4575;
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                            latitude: toCheckLatitude,
                            longitude: toCheckLongitude,
                        }))
                    },
                    (err) => {
                        if(err.message === "User denied Geolocation"){
                            reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                        } else {
                            console.log(err.message);
                            reject("An unknown error occurred, check your internet connection and try again");
                        }
                    }
                );
            }
        })
    }

    getLocationPermission(){
        const locationErrorTitle = "Location Error";
        this.getLocation().then(distance_meters => {
            localStorage.setItem('locationPermissions', "true");
            const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
            if(distance_meters <= allowed_distance){
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false
                });
            } else {
                this.setState({
                    modal:false,
                    loading:false
                });
                confirmAlert({
                    title: locationErrorTitle,
                    variables: this.props.variables,
                    message: "Too far from game area to participate!",
                    buttons: [
                        {
                            label: 'Retry',
                            onClick: () => {
                                this.checkUsersLocation()
                            }
                        }
                    ]
                });
            }
        }, error => {
            console.log(error);
            this.setState({
                modal:false,
                loading:false
            });
            localStorage.setItem('locationPermissions', "false");
            if(typeof error != "string"){
                error = error.message
            }
            confirmAlert({
                title: locationErrorTitle,
                variables: this.props.variables,
                message: error,
                buttons: [
                    {
                        label: 'Retry',
                        onClick: () => {
                            this.checkUsersLocation()
                        }
                    }
                ]
            });
        })
    }

    checkUsersLocation(){
        const variables = this.props.variables;
        const locationPermissions = localStorage.getItem('locationPermissions');
        if(!this.props.variables.collectDistance) {
            this.setState({locationChecking: false})
        } else if(locationPermissions === "false" || !locationPermissions){
            const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
            const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
            confirmAlert({
                title: locationPermissionsHeader,
                variables: variables,
                message: locationPermissionsBody,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.setState({
                                loading: true,
                            });
                            this.getLocationPermission()
                        }
                    }
                ],
            })
        } else {
            this.setState({
                loading: true,
            });
            this.getLocationPermission()
        }
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.tilesRef);
      base.removeBinding(this.correctImageRef);
      base.removeBinding(this.gameActiveRef);
      base.removeBinding(this.userHighScoreRef);
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    winnerToggle() {
        this.setState({
            winnerModal: !this.state.winnerModal,
            startConfetti: false,
            downloadedFile: null
        });
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
      localStorage.removeItem('userEmail');
      this.setState({
        userEmail:false
      })
    }

    fireWinningMessage(numberOfMoves){
      const userEmail = this.state.userEmail;
      const base64EncodedEmail = btoa(userEmail);
      const variables = this.props.variables;
      this.setState({
          startConfetti: true
      })
      if(!this.state.userHighScore || typeof this.state.userHighScore === "object" || this.state.userHighScore === 0 || this.state.userHighScore > numberOfMoves) {
          const userResponse = {};
          userResponse['timeStamp'] = Date.now();
          userResponse['numberOfMoves'] = numberOfMoves;
          userResponse['uid'] = base64EncodedEmail;
          // this.setState({
          //     loading: true,
          // })
          const vm = this;
          // fetch(`http://localhost:5000/photoscrambledev/us-central1/checkIfGetsPrize`, {
          fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/checkIfGetsPrize`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(userResponse),
          }).then(res => {
              vm.setState({
                  loading: false
              })
              Promise.resolve(res.json()).then(function (value) {
                  if(value && value.rewardImage){
                      const prizeImage = value.rewardImage;
                      let code = null;
                      if(value && value.codes && value.codes.length > 0){
                          code = value.codes[0]
                      }
                      vm.setState({
                          winningPrizeImage: prizeImage,
                          winnerModal:true,
                          winningCode: code
                      })
                  } else if (value.login_failure || !value.result) {
                      confirmAlert({
                          variables: variables,
                          title: "Error",
                          message: "Something went wrong",
                          buttons: [
                              {
                                  label: 'OK'
                              }
                          ]
                      })
                  } else {
                      let messageHeader;
                      let messageBody;
                      if (value.result === "other_reward_sent") {
                          messageHeader = variables.winningOtherPrizeHeader;
                          messageBody = variables.winningOtherPrizeBody;
                      } else if (value.result === "no_prize_sent") {
                          messageHeader = variables.winningNoPrizeHeader;
                          messageBody = variables.winningNoPrizeBody;
                      } else if(value.result === "error_committed") {
                          messageHeader = "There Was An Error";
                          messageBody = "Something went wrong, check your internet connection and try again!";
                          confirmAlert({
                              variables: variables,
                              title: messageHeader,
                              message: messageBody,
                              buttons: [
                                  {
                                      label: 'Retry',
                                      onClick: () => {
                                          vm.fireWinningMessage(numberOfMoves)
                                      }
                                  }
                              ]
                          })
                          return;
                      } else if (value.result === "main_reward_sent") {
                          messageHeader = variables.winningHeader;
                          messageBody = variables.winningBody;
                      }
                      if (!messageHeader) {
                          messageHeader = "You Solved The Puzzle!";
                      }
                      if (!messageBody) {
                          messageBody = "Nice job but can you do better? Try again and this time do it in less moves";
                      }
                      confirmAlert({
                          variables: variables,
                          title: messageHeader,
                          message: messageBody,
                          buttons: [
                              {
                                  label: 'OK',
                                  onClick: () => {
                                      vm.setState({startConfetti: false})
                                  }
                              }
                          ]
                      })
                  }
              })
          }).catch(err =>{
              console.log(err)
              vm.setState({
                  loading: false
              })
              const messageHeader = variables.winningNoPrizeHeader || "You Solved The Puzzle!";
              const messageBody = variables.winningNoPrizeBody || "Nice job but can you do better? Try again and this time do it in less moves";
              confirmAlert({
                  variables: variables,
                  title: messageHeader,
                  message: messageBody,
                  buttons: [
                      {
                          label: 'OK',
                          onClick: () => vm.setState({startConfetti: false})
                      }
                  ]
              })
          })
      } else {
            const messageHeader = variables.winningNoPrizeHeader || "You Solved The Puzzle!";
            const messageBody = variables.winningNoPrizeBody || "Nice job but can you do better? Try again and this time do it in less moves";
            confirmAlert({
              variables: variables,
              title: messageHeader,
              message: messageBody,
              buttons: [
                {
                  label: 'OK',
                  onClick: () => this.setState({startConfetti: false})
                }
              ]
            })
        }
    }

    logUserActivity(currentGame){
      const vm = this;
      if(currentGame){
        const gameid = currentGame;
        const userEmailToBase64 = btoa(this.state.userEmail);
        base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
          data:gameid,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameid,
              })
              console.log("user game logged!")
            }
          }
        })
      }
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const playingTextHeader = tenantVariables.playingTextHeader || "No Game Up";
      const playingTextBody = tenantVariables.playingTextBody || "Come back later to play";

      return(
        <div className="flex-content-container-home">
          <div className="flex-header-home">
            <div className="header-left">
              <img className="topLeftLogo" alt=""/>
            </div>
            <div className="header-right">
              <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>LOG OUT</button>
            </div>
          </div>
          <div className="intro-container-home" >
            <div className="hero-text-container">
              <img src={frontLogoImage} className="main-hero-image" alt=""/>
            </div>
            <p style={{color:logOutButtonColor}}><span className="emphasizedText">{playingTextHeader}</span> <br/><span style={{color:logOutButtonColor}}>{playingTextBody}</span></p>
          </div>
        </div>
        )
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const topLeftImage = tenantVariables.topHeaderImage || sampleAppTopImage;
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";

      return(
        <div className="flex-content-container-home">
          <div className="intro-container-home">
            <div className="grid-wrapper">
              <div className="flex-header-home">
                {/*style={{justifyContent: "flex-end"}}*/}
                {/*<button className="btn btn-logout" onClick={()=> this.fireWinningMessage(5)}>SEND EMAIL</button>*/}
                {/*  <input type="file" name="file" onChange={this.onChangeHandler}/>*/}
                <div className="header-left">
                  <button onClick={()=>this.toggle()} className="btn btn-logout" style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>Show Image</button>
                </div>
                <div className="header-right">
                  <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>LOG OUT</button>
                </div>
              </div>
              <div className="creator-awards-logo-playscreen user"><img id="header-image" src={topLeftImage} alt="" style={{margin:0}}/></div>
              <div className="spacer-md"/>
              {this.state.currentTiles && this.state.currentTiles.length > 0 &&
                <div>
                  <SlidingPuzzle winningMessage={(numberOfMoves)=>this.fireWinningMessage(numberOfMoves)} showRealImage={()=>this.toggle()} items={this.state.currentTiles} variables={tenantVariables} />
                  <div className="spacer-md"/>
                </div>
              }
              <div style={{height:"100px", width:"1px"}}/>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} id="myModal">
              <img style={{padding:10, margin:"auto"}} width={300} height={300} src={this.state.correctImage} alt=""/>
          </Modal>
          <Modal isOpen={this.state.winnerModal} toggle={this.winnerToggle} id="myOtherModal">
              <center>
                  <img style={{padding:10, margin:"auto"}} width={300} height={300} src={this.state.winningPrizeImage} alt=""/>
                  <p style={{display: this.state.winningCode ? "" : "none"}}>{this.state.winningCode}</p>
                  <div style={{marginTop:5}}>
                      {!this.state.downloadingImage ?
                          <button type="button" onClick={()=>this.download(this.state.winningPrizeImage, "yourprize.jpg")} className="btn btn-next" style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>Download</button>
                          :
                          <button type="button" className="btn btn-next" style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>
                              <i className="fa fa-spinner fa-spin" aria-hidden="true"/> Loading
                          </button>
                      }
                  </div>
                  <div style={{marginTop:5, marginBottom: 5}}>
                      <button type="button" className="btn btn-next" onClick={()=> this.winnerToggle()} style={{backgroundColor: tenantVariables.primaryColor || "black", color: tenantVariables.secondaryColor || "white"}}>Go Back</button>
                  </div>
              </center>
          </Modal>
        </div>
        )
    }

    thanksForPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const topAnswerImage = tenantVariables.topAnswerImage || sampleAppTopImage
      const primaryColor = tenantVariables.primaryColor || "black"
      const secondaryColor = tenantVariables.secondaryColor || "white"
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white"
      return(
        <div className="flex-content-container-home">
          <div className="flex-header-home">
            <div className="header-left">
              <img className="topLeftLogo" alt=""/>
            </div>
            <div className="header-right">
              <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>LOG OUT</button>
            </div>
          </div>
          <center className="creator-awards-logo-playscreen user"><img src={topAnswerImage} alt="" style={{marginBottom:"0"}}/></center>
          <div className="intro-container-home" style={{marginTop:'20px'}}>
            <div className="top-text">
                <p className="top-text-mobile"><span className="emphasizedTextAnswers" style={{backgroundColor:primaryColor, color:secondaryColor}}>My Answers</span></p>
            </div>
            {/*<AnswerItemsComponent answers={answers} questionCount={questionCount} tenantVariables={tenantVariables} onClickAnswer={()=>console.log("click")}/>*/}
            <p id="thanks-for-playing-section" style={{color:secondaryColor, display:"none", marginTop:"5%"}}><span className="thanksForPlayingLittleText">Thanks for</span><br/><span className="emphasizedText" style={{backgroundColor:primaryColor, color:secondaryColor}}>Playing</span></p>
            <div style={{width:"100%", textAlign: "center", padding:"10px"}}>
              {/*<img width="100%" src={sponsorLogo} alt="" style={{maxWidth:"380px", maxHeight:"280px"}}/>*/}
            </div>
          </div>
        </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.userHighScore && !this.state.userHighScore){
        this.removeReactAlert()
        this.setState({
          startConfetti:false
        })
      }
    }

    render() {
      const tenantVariables = this.props.variables || {};
        const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      if(!this.state.userEmail){
        return (
            <Redirect to="/login" />
        )
      }
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading} backgroundImage={backgroundImage}/>
            )
        }
      let renderMainScreen = this.renderHoldingScreen()
      if(this.state.active === true && !this.state.ended && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      } else if(this.state.ended === true && !this.state.locationChecking){
        renderMainScreen = this.thanksForPlayingScreen()
      }
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
              {this.state.startConfetti &&
                <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
              }
              {/*<div className="loader" style={{display: this.state.loading ? 'block' : 'none' }}>*/}
              {/*    <LoadingSpinner color={tenantVariables.primaryColor} loading={true} />*/}
              {/*</div>*/}
            {renderMainScreen}
          </div>
      )
    }
}

export default Main_App;
